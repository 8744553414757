export const currentChrome = 129;

const array = [
  {
    version: '21.1',
    fullVersion: '21.1.0',
    date: '2024-09-02',
    description: 'improved scroll syncing and bug fixes.',
    link: '/blog/polypane-21-1/',
    chromiumVersion: 128,
  },
  {
    version: '21',
    fullVersion: '21.0.0',
    date: '2024-08-22',
    description: 'Fix stability issues and update the rendering engine to Chromium 128.',
    link: '/blog/polypane-21/',
    chromiumVersion: 128,
  },
  {
    version: '20.1.2',
    fullVersion: '20.1.2',
    date: '2024-08-01',
    description: 'This is a bugfix release.',
    link: '/blog/polypane-20-1-the-accessibility-tree/#polypane-2012-changelog',
    chromiumVersion: 126,
  },
  {
    version: '20.1.1',
    fullVersion: '20.1.1',
    date: '2024-07-29',
    description: 'This is a bugfix release.',
    link: '/blog/polypane-20-1-the-accessibility-tree/#polypane-2011-changelog',
    chromiumVersion: 126,
  },
  {
    version: '20.1',
    fullVersion: '20.1.0',
    date: '2024-07-29',
    description:
      'A new live accessibility tree and emulation for the default font-size, new features in the Meta panel and the Elements panel as well as many other improvements.',
    link: '/blog/polypane-20-1-the-accessibility-tree/',
    chromiumVersion: 126,
  },
  {
    version: '20',
    fullVersion: '20.0.0',
    date: '2024-06-19',
    description:
      'Improves the features and performance of the Elements and Outline panel, as well as improving general browser features and stability. Running the latest version of Chromium, 126.',
    link: '/blog/polypane-20-browser-features-and-performance/',
    chromiumVersion: 126,
  },
  {
    version: '19.0.2',
    fullVersion: '19.0.2',
    date: '2024-05-28',
    description: 'This is a bugfix and performance release.',
    link: '/blog/polypane-19-workflow-improvements/#polypane-1902-changelog',
    chromiumVersion: 124,
  },
  {
    version: '19.0.1',
    fullVersion: '19.0.1',
    date: '2024-05-21',
    description: 'This is a bugfix release.',
    link: '/blog/polypane-19-workflow-improvements/#polypane-1901-changelog',
    chromiumVersion: 124,
  },
  {
    version: '19',
    fullVersion: '19.0.0',
    date: '2024-05-16',
    description: 'Updates to the elements panel, workflow improvements and an update to Chromium 124.',
    link: '/blog/polypane-19-workflow-improvements/',
    chromiumVersion: 124,
  },
  {
    version: '18.0.4',
    fullVersion: '18.0.4',
    date: '2024-03-08',
    description: 'bugfix release that improves Portal availability and fixes a blank window issue.',
    link: '/blog/polypane-18-introducing-polypane-portal/#polypane-1804-changelog',
    chromiumVersion: 122,
  },
  {
    version: '18.0.3',
    fullVersion: '18.0.3',
    date: '2024-03-06',
    description: 'Bugfix release that improves handling of localhost addressess.',
    link: '/blog/polypane-18-introducing-polypane-portal/#polypane-1803-changelog',
    chromiumVersion: 122,
  },
  {
    version: '18.0.2',
    fullVersion: '18.0.2',
    date: '2024-03-06',
    description: 'Bugfix release that improves handling of address bar autocompletion.',
    link: '/blog/polypane-18-introducing-polypane-portal/#polypane-1802-changelog',
    chromiumVersion: 122,
  },
  {
    version: '18.0.1',
    fullVersion: '18.0.1',
    date: '2024-03-05',
    description: 'Bugfix release that improves the availability of Polypane portal and fixes a number of issues.',
    link: '/blog/polypane-18-introducing-polypane-portal/#polypane-1801-changelog',
    chromiumVersion: 122,
  },
  {
    version: '18',
    fullVersion: '18.0.0',
    date: '2024-02-21',
    description:
      'Introducing Polypane Portal: a new way to test across browsers and platforms. Updated devices, better meta panel suggestions and webcomponent support, new accessibility tests and an update to Chromium 122.',
    link: '/blog/polypane-18-introducing-polypane-portal/',
    chromiumVersion: 122,
  },
  {
    version: '17.1',
    fullVersion: '17.1.0',
    date: '2024-01-17',
    description: 'Primarily a bugfix and security release, but contains a few new features.',
    link: '/blog/polypane-17-1/',
    chromiumVersion: 120,
  },
  {
    version: '17',
    fullVersion: '17.0.0',
    date: '2023-12-15',
    description:
      'Meta tag suggestions, a new reference image split slider, updates panels, debug tools and Chromium 120.',
    link: '/blog/polypane-17-meta-panel-updates-reference-image-split-slider-and-chromium-120/',
    chromiumVersion: 120,
  },
  {
    version: '16',
    fullVersion: '16.0.0',
    date: '2023-10-31',
    description:
      'Improvements to common workflows, updates to the meta panel social media previews, support for @scope in the elements panel, an update to Chromium 118 and more.',
    link: '/blog/polypane-16-workflow-improvements-scoped-styles-and-chromium-118/',
    chromiumVersion: 118,
  },
  {
    version: '15.0.1',
    fullVersion: '15.0.1',
    date: '2023-09-29',
    description: 'This version patches a security issue in Chromium.',
    link: '/blog/polypane-15-fully-featured-browser-in-the-browse-panel-performance-improvements-chromium-116-and-more/#polypane-1501-changelog',
    chromiumVersion: 116,
  },
  {
    version: '15',
    fullVersion: '15.0.0',
    date: '2023-09-11',
    description:
      'A fully featured browser in the browse panel, performance improvements across the board, search support, updates to the Elements Panel and Chromium 116.',
    link: '/blog/polypane-15-fully-featured-browser-in-the-browse-panel-performance-improvements-chromium-116-and-more/',
    chromiumVersion: 116,
  },
  {
    version: '14.1',
    fullVersion: '14.1.0',
    date: '2023-07-12',
    description:
      'Container queries in the Polypane Elements panel, color pickers now show contrast lines, the ability to force pseudo states like hover or focus on elements, a new @​layer tree overview, new devices and better handling of forms and URLs across panes.',
    link: '/blog/polypane-14-1-container-query-support-forced-element-state-and-contrast-lines-in-color-pickers/',
    chromiumVersion: 114,
  },
  {
    version: '14.0.1',
    fullVersion: '14.0.1',
    date: '2023-06-07',
    description: 'Bugfix release for 14.0.0.',
    link: '/blog/polypane-14/#polypane-1401-changelog',
    chromiumVersion: 114,
  },
  {
    version: '14',
    fullVersion: '14.0.0',
    date: '2023-06-07',
    description:
      'Test with multiple sessions, a new Elements Debug panel to quickly help you debug issues, an updated UI, new screenshot options, performance and UI improvements across the app and now running on Chromium 114.',
    link: '/blog/polypane-14/',
    chromiumVersion: 114,
  },
  {
    version: '13.1.2',
    fullVersion: '13.1.2',
    date: '2023-04-13',
    description: 'Bugfix release for 13.1.',
    link: '/blog/polypane-13-1/#polypane-1312-changelog',
    chromiumVersion: 110,
  },
  {
    version: '13.1.1',
    fullVersion: '13.1.1',
    date: '2023-04-03',
    description: 'Bugfix release for 13.1.',
    link: '/blog/polypane-13-1/#polypane-1311-changelog',
    chromiumVersion: 110,
  },
  {
    version: '13.1',
    fullVersion: '13.1.0',
    date: '2023-03-27',
    description:
      'Improved performance. Adds network requests to the Console Panel, flex and grid overlays to the Elements Panel, APCA support to the color picker and unique URLs per tab to the Browse Panel.',
    link: '/blog/polypane-13-1',
    chromiumVersion: 110,
  },
  {
    version: '13.0.3',
    fullVersion: '13.0.3',
    date: '2023-02-20',
    description: 'Bugfix release for 13, solves issues with overview screenshots.',
    link: '/blog/polypane-13-css-nesting-extension-support-in-beta-search-by-selector-and-chromium-110/#polypane-1303-changelog',
    chromiumVersion: 110,
  },
  {
    version: '13.0.2',
    fullVersion: '13.0.2',
    date: '2023-02-13',
    description:
      'Bugfix release for 13, solves issues with input event syncing, window popups and non-clickable notifications.',
    link: '/blog/polypane-13-css-nesting-extension-support-in-beta-search-by-selector-and-chromium-110/#polypane-1302-changelog',
    chromiumVersion: 110,
  },
  {
    version: '13.0.1',
    fullVersion: '13.0.1',
    date: '2023-02-02',
    description: 'Bugfix release for 13, solves issues with the panel, extensions and login flow on mac.',
    link: '/blog/polypane-13-css-nesting-extension-support-in-beta-search-by-selector-and-chromium-110/#polypane-1301-changelog',
    chromiumVersion: 110,
  },
  {
    version: '13',
    fullVersion: '13.0.0',
    date: '2023-02-01',
    description: 'CSS Nesting, extension support in beta, search by selector and Chromium 110.',
    link: '/blog/polypane-13-css-nesting-extension-support-in-beta-search-by-selector-and-chromium-110/',
    chromiumVersion: 110,
  },
  {
    version: '12.0.1',
    fullVersion: '12.0.1',
    date: '2022-12-14',
    description: 'Bugfix release for 12, solves issue with Next dev server.',
    link: '/blog/polypane-12-introducing-the-command-bar/#polypane-1201-changelog',
    chromiumVersion: 108,
  },
  {
    version: '12',
    fullVersion: '12.0.0',
    date: '2022-12-12',
    description:
      'A command bar, updates to the reference image and screenshot features, Chromium 108, new accessibility checks.',
    link: '/blog/polypane-12-introducing-the-command-bar/',
    chromiumVersion: 108,
  },
  {
    version: '11',
    fullVersion: '11.0.0',
    date: '2022-10-04',
    description: 'A JSON viewer, new accessibility tools, Chromium 106, iPhone 14 devices and screenshot updates.',
    link: '/blog/polypane-11-json-viewer-new-accessibility-tools-chromium-106-i-phone-14-devices-and-screenshot-updates/',
    chromiumVersion: 106,
  },
  {
    version: '10.0.1',
    fullVersion: '10.0.1',
    date: '2022-08-05',
    description:
      'A new detachable panel, navigation sync options, element screenshots, more features in the screenshot editor, Chromium 104 and more.',
    link: '/blog/polypane-10-detachable-panel-navigation-sync-element-screenshots-and-more/',
  },
  {
    version: '10',
    fullVersion: '10.0.0',
    date: '2022-08-05',
    description:
      'A new detachable panel, navigation sync options, element screenshots, more features in the screenshot editor, Chromium 104 and more.',
    link: '/blog/polypane-10-detachable-panel-navigation-sync-element-screenshots-and-more/',
  },
  {
    version: '9.1',
    fullVersion: '9.1.0',
    date: '2022-06-21',
    description:
      'New features to quickly test forms, a much faster and robust overview screenshot feature, 7support for the INP web vital and a new way to show the heading and landmark structure inside the panes.',
    link: '/blog/polypane-9-1-form-autofilling-fast-overview-screenshot-and-outline-panel-overlays/',
  },
  {
    version: '9',
    fullVersion: '9.0.0',
    date: '2022-05-11',
    description:
      'A new screenshot editor, structured data support, new debug tools, Chromium 102 and many other improvements.',
    link: '/blog/polypane-9-screenshot-editor-structured-data-support-and-new-debug-tools/',
  },
  {
    version: '8.1.1',
    fullVersion: '8.1.1',
    date: '2022-03-09',
    description: 'This is a bugfix release.',
    link: '/blog/polypane-8-1-resizable-element-tree-disable-js-feature-new-debug-tools-and-more/',
  },
  {
    version: '8.1',
    fullVersion: '8.1.0',
    date: '2022-03-08',
    description:
      'New debug tools, a resizeable tree view in the elements panel and many improvements to the Outline and Meta panels.',
    link: '/blog/polypane-8-1-resizable-element-tree-disable-js-feature-new-debug-tools-and-more/',
  },
  {
    version: '8',
    fullVersion: '8.0.0',
    date: '2022-01-31',
    description:
      'Polypane 8 comes with a better Elements Inspector, big performance improvements, Chromium 98 and many other new and improved features.',
    link: '/blog/polypane-8-better-elements-inspector-new-syncing-features-chromium-98-and-more/',
  },
  {
    version: '7.0.1',
    fullVersion: '7.0.1',
    date: '2021-11-17',
    description:
      '7.0.1 is a bugfix release with some new outline checks and quality of life improvements and dependency upgrades.',
    link: '/blog/polypane-7/#polypane-701-changelog',
  },
  {
    version: '7',
    fullVersion: '7.0.0',
    date: '2021-10-27',
    description:
      'Polypane 7 adds a new color picker tool, support for pseudo-elements and pseudo-classes in the Elements panel, new Web Vitals data and visualisations, an integration with Marker.io, new simulators, an update to Chromium 96 and much more.',
    link: '/blog/polypane-7/',
  },
  {
    version: '6.3.2',
    fullVersion: '6.3.2',
    date: '2021-08-25',
    description: '6.3.2 is a bugfix release with some dependency upgrades.',
    link: '/blog/polypane-6-3-tunnel-vision-simulator-webmanifest-support-updated-ui/#polypane-632',
  },
  {
    version: '6.3.1',
    fullVersion: '6.3.1',
    date: '2021-08-16',
    description:
      '6.3.1 adds the ability to set custom ignore patterns for live reload, a custom font size for live CSS, updates the Twitter and Google previews and fixes a number of bugs. ',
    link: '/blog/polypane-6-3-tunnel-vision-simulator-webmanifest-support-updated-ui/#polypane-631',
  },
  {
    version: '6.3',
    fullVersion: '6.3.0',
    date: '2021-08-05',
    description:
      'Polypane 6.3 adds a tunnel vision simulator, target size cursor debug tool, an updated UI and more checks in the Meta and Outline panels. ',
    link: '/blog/polypane-6-3-tunnel-vision-simulator-webmanifest-support-updated-ui/',
  },
  {
    version: '6.2',
    fullVersion: '6.2.0',
    date: '2021-07-14',
    description:
      'Polypane 6.2 adds HTML validation, support for Robots.txt in the Meta panel, an integration with Toybox Systems. Both the Meta panel and Outline panel have gained many new checks on your content for issues.',
    link: '/blog/polypane-6-2-html-validation-robots-txt-support-rtl-emulation-and-more/',
  },
  {
    version: '6.1',
    fullVersion: '6.1.0',
    date: '2021-06-01',
    description:
      'Polypane 6.1 adds two new debug tools and a much more performant, completely rewritten interaction syncing engine along with numerous smaller improvements and bug fixes.',
    link: '/blog/polypane-6-1-readability-content-chaos-and-rewritten-event-sync-engine/',
  },
  {
    version: '6',
    fullVersion: '6.0.0',
    date: '2021-05-12',
    description:
      'Polypane Peek makes it really easy to quickly see element info: press alt or option and hover any element to get font, color, accessibility and sizing info. Also includes Chrome 91, webvitals monitoring, new astigmatism simulators, support for color gamut emulation, container queries, spell checking and more.',
    link: '/blog/polypane-6-peek-webvitals-new-simulators-chromium-91/',
  },
];

export const currentPolypaneChromeVersion = array[0].chromiumVersion;

export default array;
